<template>
<!-- state_right -->
    <div class="dinamic_sub_container state_center">
        <div class="floating_content slide_status_pad">
            <div class="inner">
                <div class="box">
                    <div class="box_block w50 register_block floating_element floating_element_a">
                        <div class="register_block_heading">
                            Ahora elige un usuario y una contraseña...
                        </div>
                        <p>El email debe estar operativo para posibles comunicaciones. La contraseña debe contener al menos un caracter en minúscula, mayúscula y número. Y superar los 8 caracteres. Debes aceptar los términos y condiciones</p>
                    </div>
                    <div class="box_block w50 register_block floating_element floating_element_b">
                        <div class="form">
                            <div class="input_block">
                                <input type="email" placeholder="Correo" v-model="email">
                            </div>
                            <div class="input_block">
                                <input type="password" placeholder="Contraseña" v-model="password">
                            </div>
                            <div class="input_block">
                                <input type="password" placeholder="Repetir contraseña" v-model="password_confirmation">
                            </div>
                            <div class="input_block">
                                <div class="input_check">
                                    <input type="checkbox" id="c2" v-model="terms"><label for="c2"><span></span>Acepto los <router-link to="/legal" target="_new">términos y condiciones</router-link></label>
                                </div>
                            </div>
                            <div class="input_block">
                                <div style="display: none" id="errorGeneral" class="input_message ko"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg><span>No es un correo válido</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="register_slide_navigation">
                    <div class="box">
                        <div class="box_block mw50 floating_element floating_element_a" @click="$parent.stp = 1">
                            <div class="btn wide big arrow left prev">
                                <span>Anterior</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
                            </div>
                        </div>
                        <div class="box_block mw50 floating_element floating_element_b">
                            <div class="btn wide dark big arrow right next" @click="save()">
                                <span>Siguiente</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Registro',
    data: function(){
        return{
            email: null,
            password: null,
            password_confirmation: null,
            terms: false,
        }
    },
    methods: {
        save: function(){
            if(!this.email){
                this.$parent.$parent.$parent.error = {
                    type: 2,
                    msg: 'Introduce un email'
                };
                return;
            }
            if(!this.password){
                this.$parent.$parent.$parent.error = {
                    type: 2,
                    msg: 'Introduce una contraseña'
                };
                return;
            }
            if(this.password.length < 8){
                this.$parent.$parent.$parent.error = {
                    type: 2,
                    msg: 'La longitud de la contraseña debe contener al menos 8 caracteres'
                };
                return;
            }
            if(this.password != this.password_confirmation){
                this.$parent.$parent.$parent.error = {
                    type: 2,
                    msg: 'Las contraseñas no coinciden'
                };
                return;
            }
            if(!this.terms){
                this.$parent.$parent.$parent.error = {
                    type: 2,
                    msg: 'Debes aceptar los terminos y condiciones'
                };
                return;
            }

            axios
                .post(process.env.VUE_APP_URL+`users`, {
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password_confirmation
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.$parent.stp = 3;
                    else
                        this.$parent.$parent.$parent.error = {
                            type: 2,
                            msg: rs.msg
                        };
                })
                .catch(error => {
                    this.$parent.$parent.$parent.error = {
                        type: 2,
                        msg: 'Algo salio mal al guardar'
                    };
                })
        }
    }
}
</script>