<template>
    <div class="landing_screen">
        <div class="landing_screen_content">
            <h2>¿Que te apetece ver hoy?</h2>
            <div class="landing_menu">
                <div class="landing_menu_item" 
                    @click="$router.push({name: 'Cartelera', params: {families: [familia.id]}})" 
                    @mouseenter="video = `https://imgdb.vivex.tv/families/${familia.name}.png`" 
                    @mouseleave="video=0"
                    v-for="(familia, index) in familias"
                    :key="index">
                    <div class="landing_menu_btn">
                        <div class="animated_title">
                            <div>
                                <span>{{familia.name}}</span>
                                <span>{{familia.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="landing_menu_item" @click="$router.push(`/novedades`)" @mouseenter="video = require('@/assets/images/loop_04.png')" @mouseleave="video=0">
                    <div class="landing_menu_btn">
                        <div class="animated_title">
                            <div>
                                <span>Novedades</span>
                                <span>Novedades</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <label class="no_show_screen">
                <span><input type="checkbox" v-model="mostrar"></span>
                No mostrar más esta pantalla
            </label>
        </div>
        <div class="see_all_btn" @click="$router.push('/cartelera')">
            Ver todos los contenidos
        </div>
        <div class="landing_screen_bg" :style="{'background-image': `url('${video}')`}"></div>
    </div>
</template>

<script>
export default {
    name: 'Landing',
    props:{
        familias:{
            type: Array
        }
    },
    data: function(){
        return {
            video: require('@/assets/images/video_intro.png'),
            mostrar: false
        }
    },
    created: function(){
        this.mostrar = this.$store.state.landing;
    },
    watch:{
        mostrar: function(newval, oldval){
            this.$store.commit('landing', newval);
        },
        video: function(newval, oldval){
            if(!newval)
                this.video = require('@/assets/images/video_intro.png');
        }
    }
}
</script>