<template>
    <section class="who_page height_inner">
        <div class="inner">
            <div class="box_block">
                <hgroup class="section_heading centered">
                    <h3>Sobre nosotros</h3>
                </hgroup>
                <div class="text_block gold">

                    <p>VIVEX.TV nace contra la censura existente en la mayoría de las plataformas de cine convencional hacia el género cinematográfico de la pornografía y por intentar generar un cambio positivo en la forma de disfrutar del sexo en internet.</p>
                    
                    <p>VIVEX. TV, es una plataforma innovadora patrocinada por la Asociación de Productores Editores de Obras y Grabaciones Audiovisuales (APEOGA) con motivo de reflejar las aspiraciones idóneas que buscamos en la distribución de los productos de Adultos:</p>
                    
                    <p>Con el respeto máximo hacia los menores, creemos que no han de ver imágenes no adecuadas para personas que no tienen una mayoría de edad y por ello creemos que hay que crear las máximas barreras de entrada a las personas no adultas, aun siendo negativo para el negocio legal, en el que están involucrados todos los productores que están asociados a APEOGA.</p>
                    
                    <p>Queremos reflejar el trato discriminatorio y censurador que nos realizan los bancos y entidades financieras, ante nuestras peticiones repetidas y manidas, de facilitarnos plataformas de cobro convencionales, tarjetas de crédito, sistemas de cobro como Paypal como el resto de mercados legales para el funcionamiento normalizado del mercado, evitando utilizar así otros medios de cobro sin control, ubicados en países con legislación laxa en materia de control de los datos como otros problemas que conlleva de legalidades dudosas.</p>
                    
                    <p>En materia de cultura, APEOGA es el patrocinador de Los Premios Bonobo como instrumento de normalización del acto sexual, así como que sea incentivo de la utilización de nuevas tecnologías en la libertad de expresión, con numerosos apoyos por parte de personalidades del mundo del arte, de la cultura. Proyecto <a href="www.premiosbonobo" target="_new">www.premiosbonobo.com</a></p>
                    
                    <p>Apeoga se compromete en incentivar un cambio en la educación sexual, exponiendo a los jóvenes que el género cinematográfico de la pornografía no es una utilidad de enseñanza para su beneficio y bienestar sexual, sino que es eso simplemente una herramienta de ocio en el que se expresan fantasía que estas no tienen por qué ser como la vida real. Proyecto <a href="www.nomevaciles.com" target="_new">www.nomevaciles.com</a> </p>
                    
                    <p>Desde aquí damos las gracias a todas las empresas, instituciones, personas que nos apoyan en estos fines y que nos ayudan a conseguirlos.</p>
                </div>
                <div class="partner_block thirds">
                    <div class="partner_item">
                        <a href="https://www.apeoga.es/" target="_new"><img src="@/assets/images/apeoga_logo.png" alt="APEOGA"></a>
                    </div>
                    <div class="partner_item">
                        <a href="https://www.nomevaciles.com/" target="_new"><img src="@/assets/images/nomevaciles_logo.png" alt="nomevaciles.com"></a>
                    </div>
                    <div class="partner_item">
                        <a href="https://www.premiosbonobo.com/" target="_new"><img src="@/assets/images/premios_bonobo_logo.png" alt="Premios Bonobo"></a>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
export default {
    name: 'About'
}
</script>