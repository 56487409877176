<template>
<!-- state_right -->
    <div class="dinamic_sub_container state_center">
        <div class="floating_content slide_status_pad">
            <div class="inner">
                <div class="box">
                    <div class="box_block w50 register_block floating_element floating_element_a">
                        <div class="register_block_heading">Estos datos nunca son almacenados</div>
                        <h3>Pero te tenemos que molestar...</h3>
                        <p>En nuestro afán por proteger a los menores del contenido para adultos hemos pensado que qué mejor manera de hacerlo que pidiéndote que introduzcas las cifras y letras que figuran en la parte inferior del reverso de tu D.N.I.</p>
                        <p>De esta forma garantizamos que eres un adulto.</p>
                        <p>Muchas gracias por tu compresión, este pequeño esfuerzo tendrá una recompensa más adelante...</p>
                    </div>
                    <div class="box_block w50 register_block floating_element floating_element_b">
                        <div class="dni_template">
                            <img src="@/assets/images/dni_template.png">
                        </div>
                        <div class="form dni_form">
                            <div class="input_block">
                                <div class="dni_input">
                                    <input type="text" ref="uno" :class="{dni_ok: unoBien}" placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXX" maxlength="25" v-model="uno">
                                    <label>01</label>
                                </div>
                            </div>
                            <div class="input_block">
                                <div class="dni_input second_line_first_group">
                                    <input type="text" ref="dos" :class="{dni_ok: dosBien}" placeholder="XXXXXXXXXXXXXXXXXX" v-model="dos" maxlength="18" :disabled="!unoBien">
                                    <label>02</label>
                                </div>
                            </div>
                            <div class="input_block">
                                <div class="dni_input second_line_last_character">
                                    <div class="pad">
                                        <input type="text" ref="tres" maxlength="1" :class="{dni_ok: tresBien}" placeholder="X" v-model="tres" :disabled="!dosBien">
                                        <label>03</label>
                                    </div>
                                </div>
                            </div>
                            <div class="input_block">
                                <div class="dni_input">
                                    <input type="text" ref="cuatro" :class="{dni_ok: cuatroBien}" placeholder="Apellido<apellido<<nombre<<<<<" v-model="cuatro" :disabled="!tresBien">
                                    <label>04</label>
                                </div>
                            </div>
                        </div>
                        <p class="provider_link">Powered by <a href="https://www.faudiovisual.com/" target="_new">
                            <svg version="1.2" baseProfile="tiny" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 283.5 71" xml:space="preserve">
                                <polygon fill="#168D9F" points="68.4,59.3 82,59.3 75.2,71.1 61.5,71.1 "/>
                                <polygon fill="#168D9F" points="41,59.3 54.7,59.3 47.9,71.1 34.2,71.1 "/>
                                <polygon fill="#168D9F" points="20.6,47.4 6.9,71.1 20.6,71.1 27.4,59.3 "/>
                                <polygon fill="#168D9F" points="34.2,23.8 29.7,31.7 27.4,35.6 41,35.6 41,35.6 "/>
                                <polygon fill="#52B9CC" points="0.1,59.3 6.9,71.1 20.6,47.4 34.2,71.1 41,59.3 27.4,35.6 34.2,23.8 61.5,71.1 68.4,59.3 34.2,0.1 
                                    "/>
                                <polygon fill="#BAE1ED" points="34.2,0.1 47.9,0.1 82,59.3 68.4,59.3 "/>
                                <polygon fill="#BAE1ED" points="27.4,35.6 41,59.3 54.7,59.3 41,35.6 "/>
                                <path fill="#FFFFFF" d="M100,4.4h18v4.7h-12.4V15h10.7v4.4h-10.7V30H100V4.4z"/>
                                <path fill="#FFFFFF" d="M119.6,17.2c0.3-4.8,4.6-6.2,8.7-6.2c3.7,0,8.1,0.8,8.1,5.3v9.6c0,1.7,0.2,3.4,0.6,4.1h-5.2
                                    c-0.2-0.6-0.3-1.2-0.4-1.8c-1.6,1.7-4,2.3-6.2,2.3c-3.5,0-6.3-1.8-6.3-5.6c0-4.2,3.2-5.2,6.3-5.6c3.1-0.5,6-0.4,6-2.4
                                    c0-2.2-1.5-2.5-3.3-2.5c-1.9,0-3.2,0.8-3.4,2.8H119.6z M131.3,20.9c-0.9,0.8-2.7,0.8-4.2,1.1c-1.6,0.3-3,0.9-3,2.7
                                    c0,1.9,1.5,2.4,3.1,2.4c4,0,4.1-3.2,4.1-4.3V20.9z"/>
                                <path fill="#FFFFFF" d="M152.1,18c-0.3-2.1-1.6-3.2-3.8-3.2c-3.3,0-4.3,3.3-4.3,6c0,2.7,1,5.8,4.2,5.8c2.4,0,3.7-1.5,4.1-3.8h4.9
                                    c-0.6,4.9-4.1,7.6-8.9,7.6c-5.6,0-9.4-3.9-9.4-9.5c0-5.8,3.4-10,9.5-10c4.4,0,8.4,2.3,8.7,7H152.1z"/>
                                <path fill="#FFFFFF" d="M165.6,11.5h3.7v3.4h-3.7V24c0,1.7,0.4,2.2,2.2,2.2c0.5,0,1,0,1.6-0.1v4c-0.9,0.1-2,0.2-3,0.2
                                    c-3.1,0-5.8-0.7-5.8-4.4V14.9h-3.1v-3.4h3.1V5.9h5.1V11.5z"/>
                                <path fill="#FFFFFF" d="M179.9,11c5.8,0,9.6,3.9,9.6,9.8c0,5.9-3.8,9.7-9.6,9.7c-5.8,0-9.6-3.9-9.6-9.7
                                    C170.3,14.8,174.1,11,179.9,11z M179.9,26.6c3.5,0,4.5-3,4.5-5.9c0-3-1-5.9-4.5-5.9c-3.4,0-4.5,3-4.5,5.9
                                    C175.4,23.7,176.4,26.6,179.9,26.6z"/>
                                <path fill="#FFFFFF" d="M191.9,11.5h4.8v3.4h0.1c0.9-2.3,3.4-3.9,5.9-3.9c0.4,0,0.8,0.1,1.1,0.2v4.7c-0.5-0.1-1.2-0.2-1.8-0.2
                                    c-3.7,0-5,2.7-5,5.9V30h-5.1V11.5z"/>
                                <path fill="#FFFFFF" d="M208.5,9.2h-3.5l3.4-5.1h5.6L208.5,9.2z M205.1,11.5h5.1V30h-5.1V11.5z"/>
                                <path fill="#FFFFFF" d="M213.1,17.2c0.3-4.8,4.6-6.2,8.7-6.2c3.7,0,8.1,0.8,8.1,5.3v9.6c0,1.7,0.2,3.4,0.6,4.1h-5.2
                                    c-0.2-0.6-0.3-1.2-0.4-1.8c-1.6,1.7-4,2.3-6.2,2.3c-3.5,0-6.3-1.8-6.3-5.6c0-4.2,3.2-5.2,6.3-5.6c3.1-0.5,6-0.4,6-2.4
                                    c0-2.2-1.5-2.5-3.3-2.5c-1.9,0-3.2,0.8-3.4,2.8H213.1z M224.8,20.9c-0.9,0.8-2.7,0.8-4.2,1.1c-1.6,0.3-3,0.9-3,2.7
                                    c0,1.9,1.5,2.4,3.1,2.4c4,0,4.1-3.2,4.1-4.3V20.9z"/>
                                <path fill="#FFFFFF" d="M106.9,40.2h5.8l9.6,25.6h-5.8l-1.9-5.7h-9.6l-2,5.7h-5.7L106.9,40.2z M106.4,55.9h6.6l-3.2-9.4h-0.1
                                    L106.4,55.9z"/>
                                <path fill="#FFFFFF" d="M140.5,65.8h-4.8v-2.6h-0.1c-1.3,2.1-3.5,3.1-5.7,3.1c-5.4,0-6.8-3-6.8-7.6V47.3h5.1v10.5
                                    c0,3,0.9,4.6,3.3,4.6c2.8,0,3.9-1.5,3.9-5.3v-9.7h5.1V65.8z"/>
                                <path fill="#FFFFFF" d="M156.7,63.5L156.7,63.5c-1.3,2-3.3,2.9-5.7,2.9c-5.6,0-8.3-4.8-8.3-9.9c0-5,2.8-9.6,8.2-9.6
                                    c2.2,0,4.3,0.9,5.5,2.8h0.1v-9.3h5.1v25.6h-4.8V63.5z M152.2,50.6c-3.3,0-4.5,2.9-4.5,5.9c0,2.9,1.3,5.9,4.5,5.9
                                    c3.4,0,4.4-2.9,4.4-6C156.6,53.5,155.5,50.6,152.2,50.6z"/>
                                <path fill="#FFFFFF" d="M169.7,44.4h-5.1v-4.2h5.1V44.4z M164.6,47.3h5.1v18.5h-5.1V47.3z"/>
                                <path fill="#FFFFFF" d="M181.8,46.8c5.8,0,9.6,3.9,9.6,9.8c0,5.9-3.8,9.7-9.6,9.7c-5.8,0-9.6-3.9-9.6-9.7
                                    C172.2,50.7,176,46.8,181.8,46.8z M181.8,62.5c3.5,0,4.5-3,4.5-5.9c0-3-1-5.9-4.5-5.9c-3.4,0-4.5,3-4.5,5.9
                                    C177.3,59.5,178.4,62.5,181.8,62.5z"/>
                                <path fill="#FFFFFF" d="M204.1,65.8h-5.7L192,47.3h5.3l3.9,12.7h0.1l3.9-12.7h5.1L204.1,65.8z"/>
                                <path fill="#FFFFFF" d="M216.8,44.4h-5.1v-4.2h5.1V44.4z M211.7,47.3h5.1v18.5h-5.1V47.3z"/>
                                <path fill="#FFFFFF" d="M223.8,59.8c0,2.2,1.9,3.1,3.9,3.1c1.5,0,3.3-0.6,3.3-2.4c0-1.5-2.1-2.1-5.8-2.9c-2.9-0.6-5.9-1.7-5.9-4.9
                                    c0-4.7,4.1-5.9,8.1-5.9c4,0,7.8,1.4,8.2,5.9h-4.8c-0.1-2-1.6-2.5-3.5-2.5c-1.1,0-2.8,0.2-2.8,1.7c0,1.8,2.9,2.1,5.8,2.8
                                    c3,0.7,5.9,1.8,5.9,5.2c0,4.9-4.2,6.5-8.5,6.5c-4.3,0-8.5-1.6-8.7-6.5H223.8z"/>
                                <path fill="#FFFFFF" d="M255.6,65.8h-4.8v-2.6h-0.1c-1.3,2.1-3.5,3.1-5.7,3.1c-5.4,0-6.8-3-6.8-7.6V47.3h5.1v10.5
                                    c0,3,0.9,4.6,3.3,4.6c2.8,0,3.9-1.5,3.9-5.3v-9.7h5.1V65.8z"/>
                                <path fill="#FFFFFF" d="M258.4,53c0.3-4.8,4.6-6.2,8.7-6.2c3.7,0,8.1,0.8,8.1,5.3v9.6c0,1.7,0.2,3.4,0.6,4.1h-5.2
                                    c-0.2-0.6-0.3-1.2-0.4-1.8c-1.6,1.7-4,2.3-6.2,2.3c-3.5,0-6.3-1.8-6.3-5.6c0-4.2,3.2-5.2,6.3-5.6c3.1-0.5,6-0.4,6-2.4
                                    c0-2.2-1.5-2.5-3.3-2.5c-1.9,0-3.2,0.8-3.4,2.8H258.4z M270.2,56.8c-0.9,0.8-2.7,0.8-4.2,1.1c-1.6,0.3-3,0.9-3,2.7
                                    c0,1.9,1.5,2.4,3.1,2.4c4,0,4.1-3.2,4.1-4.3V56.8z"/>
                                <path fill="#FFFFFF" d="M278.4,40.2h5.1v25.6h-5.1V40.2z"/>
                                </svg>
                            </a>
                        </p>
                    </div>
                </div>
                <div class="register_slide_navigation">
                    <div class="box">
                        <div class="box_block mw50 floating_element floating_element_a">
                            <div class="btn wide big arrow left" @click="$router.push('/')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
                                <span>Anterior</span>
                            </div>
                        </div>
                        <div class="box_block mw50 floating_element floating_element_b">
                            <div class="btn wide dark big arrow right" :class="{disabled: !uno || !dos || !tres || !cuatro || !unoBien || !dosBien || !tresBien || !cuatroBien}" @click="check()">
                                <span>Siguiente</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal_message_wrapper" v-if="this.isMenor">
            <div class="modal_message ko">
                <div class="close_btn" @click="() => {this.isMenor = false;}">
                    <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 8.933-2.721-2.722c-.146-.146-.339-.219-.531-.219-.404 0-.75.324-.75.749 0 .193.073.384.219.531l2.722 2.722-2.728 2.728c-.147.147-.22.34-.22.531 0 .427.35.75.751.75.192 0 .384-.073.53-.219l2.728-2.728 2.729 2.728c.146.146.338.219.53.219.401 0 .75-.323.75-.75 0-.191-.073-.384-.22-.531l-2.727-2.728 2.717-2.717c.146-.147.219-.338.219-.531 0-.425-.346-.75-.75-.75-.192 0-.385.073-.531.22z" fill-rule="nonzero"/></svg>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.971 0h-9.942l-7.029 7.029v9.941l7.029 7.03h9.941l7.03-7.029v-9.942l-7.029-7.029zm-1.402 16.945l-3.554-3.521-3.518 3.568-1.418-1.418 3.507-3.566-3.586-3.472 1.418-1.417 3.581 3.458 3.539-3.583 1.431 1.431-3.535 3.568 3.566 3.522-1.431 1.43z"/></svg>
                <h2>Uuups! este D.N.I. pertenece a un menor...</h2>
                <h3>Cuando cumplas la mayoría de edad vuelve, ¡te estaremos esperando!</h3>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'STP1',
    data: function(){
        return{
            uno: null,
            dos: null,
            tres: null,
            cuatro: null,

            unoBien: false,
            dosBien: false,
            tresBien: false,
            cuatroBien: false,
            isMenor: false,
            defaultError: "Hubo un error, revisa los datos"
            //error: null
        }
    },
    methods: {
        ln1: function(){
            let params = new URLSearchParams();
            params.append('ln', this.uno);
            axios
                .post(process.env.VUE_APP_URL_EIDENTIDAD+'ln1', params)
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.unoBien = true;
                        this.$refs.dos.focus();
                    }
                    else{
                        this.$parent.$parent.$parent.error = {type: 2, msg: rs.msg ? rs.msg : defaultError};
                    }
                })
                .catch(error => {
                    this.unoBien = false;
                    this.dosBien = false;
                    this.tresBien = false;
                    this.cuatroBien = false;
                    this.$parent.$parent.$parent.error = {type: 2, msg: 'Algo salio mal'};
                })
        },
        ln2: function(){
            let params = new URLSearchParams();
            params.append('ln', this.dos);
            axios
                .post(process.env.VUE_APP_URL_EIDENTIDAD+'ln2', params)
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.dosBien = true;
                        this.$refs.tres.focus();
                    }
                    else{
                        this.$parent.$parent.$parent.error = {type: 2, msg: rs.msg ? rs.msg : defaultError};
                    }
                })
                .catch(error => {
                    this.dosBien = false;
                    this.tresBien = false;
                    this.cuatroBien = false;
                    this.$parent.$parent.$parent.error = {type: 2, msg: 'Algo salio mal'};
                })
        },
        ln3: function(){
            let params = new URLSearchParams();
            params.append('ln1', this.uno);
            params.append('ln2', this.dos);
            params.append('ln3', this.tres);
            axios
                .post(process.env.VUE_APP_URL_EIDENTIDAD+'ln3', params)
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.tresBien = true;
                        this.$refs.cuatro.focus();
                    }
                    else{
                        this.$parent.$parent.$parent.error = {type: 2, msg: rs.msg ? rs.msg : defaultError};
                    }
                })
                .catch(error => {
                    this.tresBien = false;
                    this.cuatroBien = false;
                    this.$parent.$parent.$parent.error = {type: 2, msg: 'Algo salio mal'};
                })
        },
        check: function(){
            let params = new URLSearchParams();
            params.append('ln1', this.uno);
            params.append('ln2', this.dos);
            params.append('ln3', this.tres);
            params.append('ln4', this.cuatro);
            axios
                .post(process.env.VUE_APP_URL_EIDENTIDAD+'all', params)
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.$parent.stp = 2;
                    }
                    else if(rs.state == "KO2"){
                        this.isMenor = true;
                    }
                    else{
                        this.$parent.$parent.$parent.error = {type: 2, msg: rs.msg ? rs.msg : defaultError};
                    }
                })
                .catch(error => {
                    this.$parent.$parent.$parent.error = {type: 2, msg: 'Algo salio mal'};
                })
        }
    },
    watch: {
        uno: function(newval, oldval){
            this.uno = newval.toUpperCase();
            if(this.uno.length >= 24)
                this.ln1();
            else{
                this.unoBien = false;
            }
        },
        dos: function(newval, oldval){
            this.dos = newval.toUpperCase();
            if(this.dos.length >= 18)
                this.ln2();
            else{
                this.dosBien = false;
            }
        },
        tres: function(newval, oldval){
            this.tres = newval.toUpperCase();
            if(this.tres.length >= 1)
                this.ln3();
            else{
                this.tresBien = false;
            }
        },
        cuatro: function(newval, oldval){
            this.cuatro = newval.toUpperCase();
            if(this.cuatro.length >= 1)
                this.cuatroBien = true;
            else
                this.cuatroBien = false;
        }
    },
    mounted: function(){
        setTimeout(() => {this.$refs.uno.focus();}, 1000);
    }
}
</script>