<template>
    <div class="dinamic_container state_center">
        <div class="register_slide_status_container" :class="{active: stp > 0}">
            <div class="register_slide_status" :class="{first_step: stp==1, second_step: stp==2, third_step: stp==3}">
                <div><span>1</span></div>
                <div><span>2</span></div>
                <div><span>3</span></div>
            </div>
        </div>
        <transition :name="animationType">
            <Stp1 v-if="stp==1"/>
            <Stp2 v-else-if="stp==2"/>
            <Stp3 v-else-if="stp==3"/>
        </transition>
    </div>
</template>

<script>
import Stp1 from '@/components/Externa/Stp1.vue';
import Stp2 from '@/components/Externa/Stp2.vue';
import Stp3 from '@/components/Externa/Stp3.vue';

export default {
    name: 'Stp',
    components:{
        Stp1,
        Stp2,
        Stp3
    },
    data: function(){
        return{
            stp: 1,
            animationType: 'interna_right'
        }
    },
    watch: {
        stp: function(newval, oldval){
            if(newval > oldval)
                this.animationType = 'interna_right';
            else
                this.animationType = 'interna_left';
        }
    }
}
</script>

<style>
    .interna_right-enter, .interna_left-leave-to{
        -webkit-transform: translate3d(100vw,0,0) !important;
        transform: translate3d(100vw,0,0) !important;
    }
    .interna_right-enter-to, .interna_right-leave, .interna_left-enter-to, .interna_left-leave{
        -webkit-transform: none;
        transform: none;
    }
    .interna_right-leave-to, .interna_left-enter{
        -webkit-transform: translate3d(-100vw,0,0) !important;
        transform: translate3d(-100vw,0,0) !important;
    }
</style>