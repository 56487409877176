<template>
    <section class="intranet height_inner">
        <div class="inner height_bottom_inner">
            <hgroup class="section_heading centered">
                <h3>Mi cuenta</h3>
            </hgroup>
            <div class="text_message ok top_message center_message">
                Tu suscripción mensual esta <b>activa. </b>
                <router-link to="/premium">Renovar suscripción</router-link>
            </div>
            <div class="intranet_tabs">
                <div class="intranet_nav">
                    <h3>Menú</h3>
                    <ul>
                        <li><div class="intranet_nav_btn" @click="getPremiums()" :class="{active: tab==1}">Premium</div></li>
                        <li><div class="intranet_nav_btn" @click="tab=2" :class="{active: tab==2}">Datos personales</div></li>
                        <li><div class="intranet_nav_btn" @click="getHistorial()" :class="{active: tab==3}">Historial</div></li>
                        <li><div class="intranet_nav_btn" @click="getFavoritos()" :class="{active: tab==4}">Favoritos</div></li>
                    </ul>
                </div>
                <div class="intranet_content">
                    <div class="loader transparent" style="opacity: 1" v-if="!tab">
                        <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div class="tab_content">
                        <transition name="tabIntranet" mode="out-in">
                            <Premium :number="number" :premiums="premiums" v-if="premiums && number && tab==1"/>
                            <Usuario v-else-if="tab==2"/>
                            <Historial v-else-if="history && tab==3" :history="history"/>
                            <Favoritos v-else-if="favorites && tab==4" :favorites="favorites"/>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Premium from '@/components/Intranet/Premium.vue';
import Usuario from '@/components/Intranet/Usuario.vue';
import Historial from '@/components/Intranet/Historial.vue';
import Favoritos from '@/components/Intranet/Favoritos.vue';
import axios from 'axios';

export default {
    name: 'Cuenta',
    components:{
        Premium,
        Usuario,
        Historial,
        Favoritos
    },
    data: function(){
        return {
            tab: 0,
            premiums: [],
            number: null,
            history: [],
            favorites: [],
        }
    },
    methods:{
        getPremiums: function(){
            this.tab = 0;
            axios
                .get(process.env.VUE_APP_URL+`premiums`, {
                    params:{
                        user: this.$store.state.user
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.premiums = rs.data;
                        this.tab = 1;
                    }
                })
                .catch(error => this.$response_error(error))
        },
        getNumber: function(){
            axios
                .get(process.env.VUE_APP_URL+`code`, {
                    params:{
                        token: this.$store.state.user
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.number = rs.data;
                    }
                })
                .catch(error => this.$response_error(error))
        },
        getHistorial: function(){
            this.tab = 0;
            axios
                .get(process.env.VUE_APP_URL+`histories`, {
                    params:{
                        user: this.$store.state.user,
                        'orderBy[column]': 'updated_at',
                        'orderBy[order]': 'desc'
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.history = rs.data;
                        this.tab = 3;
                    }
                })
                .catch(error => this.$response_error(error))
        },
        getFavoritos: function(){
            this.tab = 0;
            axios
                .get(process.env.VUE_APP_URL+`favorites`, {
                    params:{
                        user: this.$store.state.user,
                        'orderBy[column]': 'updated_at',
                        'orderBy[order]': 'desc'
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.favorites = rs.data;
                        this.tab = 4;
                    }
                })
                .catch(error => this.$response_error(error))
        }
    },
    created: function(){
        this.getPremiums();
        this.getNumber();
    }
}
</script>

<style>
    .tabIntranet-enter-active, .tabIntranet-leave-active {
        transition: opacity .5s;
    }
    .tabIntranet-enter, .tabIntranet-leave-to{
        opacity: 0;
    }
</style>