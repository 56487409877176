<template>
    <div class="dinamic_container full_container state_center">
        <div class="floating_content">
            <div class="inner">
                <div class="box">
                    <div class="box_block w50 offsetw25 login_block floating_element floating_element_a">
                        <div class="h2">Iniciar sesión</div>
                        <form ref="form" class="form" @submit.prevent="login()">
                            <div class="input_block">
                                <input ref="username" type="text" v-model="username" placeholder="Correo" tabindex="0" class="fixed">
                            </div>
                            <div class="input_block">
                                <input type="password" v-model="password" placeholder="Contraseña" tabindex="0" class="fixed">
                            </div>
                            <div class="input_block">
                                <button type="submit" class="btn wide big down" :class="{loading: loading}">
                                    <div class="loader black"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                                    <span>Iniciar sesión</span>
                                </button>
                            </div>
                            <div class="box">
                                <div class="box_block" @click="$router.push('/recuperar')">
                                    <div class="input_block">
                                        <div class="simple_link dark icon_link"><svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M23.621 9.012c.247.959.379 1.964.379 3 0 6.623-5.377 11.988-12 11.988s-12-5.365-12-11.988c0-6.623 5.377-12 12-12 2.581 0 4.969.822 6.927 2.211l1.718-2.223 1.935 6.012h-6.58l1.703-2.204c-1.62-1.128-3.582-1.796-5.703-1.796-5.52 0-10 4.481-10 10 0 5.52 4.48 10 10 10 5.519 0 10-4.48 10-10 0-1.045-.161-2.053-.458-3h2.079zm-7.621 7.988h-8v-6h1v-2c0-1.656 1.344-3 3-3s3 1.344 3 3v2h1v6zm-5-8v2h2v-2c0-.552-.448-1-1-1s-1 .448-1 1z"/></svg>Olvidé mi contraseña...</div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Login',
    data: function(){
        return{
            username: null,
            password: null,
            //error: null,
            loading: false
        }
    },
    methods: {
        login: function(){
            if(!this.username){
                this.$parent.$parent.error = {type: 2, msg: 'Rellena un email'};
                return;
            }
            if(!this.password){
                this.$parent.$parent.error = {type: 2, msg: 'Rellena una contraseña'};
                return;
            }
            this.loading = true;
            axios
                .post(process.env.VUE_APP_URL+`login`, {
                    email: this.username,
                    password: this.password
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.$store.commit('login', rs.data);
                        this.$router.push('/');
                    }
                    else{
                        this.$parent.$parent.error = {type: 2, msg: rs.msg};
                    }
                })
                .catch(error => {
                    this.$parent.$parent.error = {type: 2, msg: 'Hubo un problema al conectar'};
                })
                .finally(() => this.loading = false)
        }
    },
    mounted: function(){
        setTimeout(() => {this.$refs.username.focus();}, 1000);
    }
}
</script>