<template>
    <section class="subscribe_data height_inner">
        <div class="inner">
            <hgroup class="section_heading centered">
                <h2>vivex Premium</h2>
            </hgroup>
            <div class="subscribe_content">
                <div class="subscribe_content_line">
                    <div class="box">
                        <div class="box_block w30">
                            <div class="premium_steps">
                                <span>01</span>Escoge una tarifa
                            </div>
                        </div>
                        <div class="box_block w70">
                            <div class="data_box bordered premium_prices">
                                <div class="data_box_item price_toggle" :class="{price_toggle_actual: tarifa==3}" @click="tarifa=3">
                                    <div class="units">9</div>
                                    <div class="units_aside">
                                        <div class="range">Mensual</div>
                                        <div class="decimals">,95 €</div>
                                        <span style="display: block;font-size: 0.563em;margin-top: 5px;text-align: right;">IVA Incluido</span>
                                    </div>
                              </div>
                                <div class="data_box_item price_toggle" :class="{price_toggle_actual: tarifa==4}" @click="tarifa=4" style="position: relative;">
                                    <div class="offer_label"><b>Recomendado</b>¡Ahorras más de el 50% al año!</div>
                                    <div class="units">59</div>
                                    <div class="units_aside">
                                        <div class="range">Anual</div>
                                        <div class="decimals">,99 €</div>
                                        <span style="display: block;font-size: 0.563em;margin-top: 5px;text-align: right;">IVA Incluido</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <transition name="premium" mode="out-in">
                    <div class="subscribe_content_line" v-if="tarifa">
                        <div class="box">
                            <div class="box_block w30">
                                <div class="premium_steps">
                                    <span>02</span>Modo de pago
                                </div>
                            </div>
                            <div class="box_block w70">
                                <div class="data_box bordered payment_method">
                                    <div class="data_box_item payment_method_toggle" :class="{payment_method_actual: pago==1}" @click="pago=1">
                                        <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 2h-14c-1.104 0-2 .896-2 2v4h16v3.5c0 .276-.224.5-.5.5h-1.5v2h2c1.104 0 2-.896 2-2v-8c0-1.104-.896-2-2-2zm0 3h-14v-.5c0-.276.224-.5.5-.5h13c.276 0 .5.224.5.5v.5zm-6 5h-14c-1.104 0-2 .896-2 2v8c0 1.104.896 2 2 2h14c1.104 0 2-.896 2-2v-8c0-1.104-.896-2-2-2zm-11 10h-2v-1h2v1zm3 0h-2v-1h2v1zm.32-3.377c-.383.239-.836.377-1.32.377-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5c.484 0 .937.138 1.32.377-.531.552-.857 1.3-.857 2.123 0 .824.326 1.571.857 2.123zm3.68 3.377h-2v-1h2v1zm-1-3c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zm4 3h-2v-1h2v1z"/></svg></span>
                                        <div class="payment_method_item ">Pago por tarjeta</div>
                                    </div>
                                    <div class="data_box_item payment_method_toggle" :class="{payment_method_actual: pago==2}" @click="pago=2">
                                        <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.164 7.165c-1.15.191-1.702 1.233-1.231 2.328.498 1.155 1.921 1.895 3.094 1.603 1.039-.257 1.519-1.252 1.069-2.295-.471-1.095-1.784-1.827-2.932-1.636zm1.484 2.998l.104.229-.219.045-.097-.219c-.226.041-.482.035-.719-.027l-.065-.387c.195.03.438.058.623.02l.125-.041c.221-.109.152-.387-.176-.453-.245-.054-.893-.014-1.135-.552-.136-.304-.035-.621.356-.766l-.108-.239.217-.045.104.229c.159-.026.345-.036.563-.017l.087.383c-.17-.021-.353-.041-.512-.008l-.06.016c-.309.082-.21.375.064.446.453.105.994.139 1.208.612.173.385-.028.648-.36.774zm10.312 1.057l-3.766-8.22c-6.178 4.004-13.007-.318-17.951 4.454l3.765 8.22c5.298-4.492 12.519-.238 17.952-4.454zm-2.803-1.852c-.375.521-.653 1.117-.819 1.741-3.593 1.094-7.891-.201-12.018 1.241-.667-.354-1.503-.576-2.189-.556l-1.135-2.487c.432-.525.772-1.325.918-2.094 3.399-1.226 7.652.155 12.198-1.401.521.346 1.13.597 1.73.721l1.315 2.835zm2.843 5.642c-6.857 3.941-12.399-1.424-19.5 5.99l-4.5-9.97 1.402-1.463 3.807 8.406-.002.007c7.445-5.595 11.195-1.176 18.109-4.563.294.648.565 1.332.684 1.593z"/></svg></span>
                                        <div class="payment_method_item">Transferencia bancaria</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="premium" mode="out-in">
                    <div class="subscribe_content_line" v-if="pago==1">
                        <div class="box">
                            <div class="box_block w30">
                                <div class="premium_steps">
                                    <span>03</span>Proceder al pago
                                </div>
                            </div>
                            <div class="box_block w70">
                                <p>A continuación se abrirá una ventana nueva que redirigirá a la página del banco.</p>
                                <div class="btn dark big icon right next" @click="pagar()" :class="{loading: enviando}"><span>Proceder al pago<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4.062 16.626c-.165-.5.038-.921.656-1.145l3.249-1.155-.134 1.028c-.077.589-.103 1.107-.089 1.573-.25.078-1.095.339-1.945.552-.871.218-1.538-.25-1.737-.853zm1.745 1.916c-.461.167-.612.48-.489.854.147.449.645.798 1.294.635.606-.151 1.408-.396 1.701-.487-.203-.505-.378-1.035-.479-1.659l-2.027.657zm.193-10.542h2.643l.128-1h-2.771v1zm16-6v9c0 1.104-.896 2-2 2h-1.989l.015.02c-.031.43-.105.906-.222 1.457-.451 2.144-1.637 5.122-.83 8.418-2.312.356-3.651.523-5.992 1.105-.273-4.062-2.266-4.943-1.804-8.47.542-4.137.844-6.461 1.196-9.255.11-.879.731-1.307 1.337-1.307.631 0 1.246.464 1.252 1.366.021 3.303.108 6.593.324 7.393.146.54 1.087.638 1.087-.512l-.002-2.216h5.128c.276 0 .5-.224.5-.5v-4.499h-5.568c-.157-1.461-1.27-2.531-2.721-2.531h-.001c-1.44 0-2.578 1.038-2.806 2.531h-4.904v4.5c0 .276.224.5.5.5h3.757l-.261 2h-3.996c-1.104 0-2-.896-2-2v-9c0-1.104.896-2 2-2h16c1.104 0 2 .896 2 2zm-2 .5c0-.276-.224-.5-.5-.5h-15c-.276 0-.5.224-.5.5v.5h16v-.5zm-14 7.5h2.386l.129-1h-2.515v1zm12-3h-3v1h3v-1z"/></svg></span></div>
                            </div>
                        </div>
                    </div>
                    <div v-if="pago==2">
                        <div class="subscribe_content_line">
                            <div class="box">
                                <div class="box_block w30">
                                </div>
                                <div class="box_block w70">
                                    <p>Para completar el pago, debes realizar una transferencia por el importe total de la tarifa que elijas a la siguiente cuenta bancaria indicando tu número de identificación personal en el concepto de la transferencia bancaria.</p>
                                    <div class="data_box bordered ">
                                        <div class="account_number">{{cuenta}}</div>
                                        <div class="copy_btn copy" 
                                            v-clipboard:copy="cuenta"
                                            v-clipboard:success="() => $parent.error = {type: 1, msg: 'Copiado al portapapeles'}"
                                            v-clipboard:error="() => $parent.error = {type: 2, msg: 'Ocurrio un problema'}"
                                        >Copiar</div>
                                    </div>
                                    <div class="data_box bordered id_number">
                                        <div class="data_box_item text">Tu número de identificación es:</div>
                                        <div class="data_box_item personal_id_number">{{numero}}</div>
                                        <div class="copy_btn copy"
                                            v-clipboard:copy="numero"
                                            v-clipboard:success="() => $parent.error = {type: 1, msg: 'Copiado al portapapeles'}"
                                            v-clipboard:error="() => $parent.error = {type: 2, msg: 'Ocurrio un problema'}"
                                        >Copiar</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="subscribe_content_line">
                            <div class="box">
                                <div class="box_block w30">
                                    <div class="premium_steps">
                                        <span>03</span>Justificante de pago
                                    </div>
                                </div>
                                <div class="box_block w70">
                                    <p>Debido a que la transferencia bancaria puede demorarse 24/48 horas, nosotros confiamos en ti y solo te pedimos que nos adjuntes el justificante de pago para que puedas empezar a disfrutar de nuestros contenidos ya mismo (archivos compatibles: jpg, png, bmp, tiff y pdf).</p>
                                    <div @click="subirJustificante(1)" class="btn big icon right next"><span>Cargar justificante de pago<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.812 23c-7.812 0-7.812-8-7.812-8h-3l4.979-4 5.021 4h-3s-.812 6.094 3.812 8zm1.667-14.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h3.302c-.275-.717-.46-1.404-.578-2h-2.724c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78 3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-2.518c.062.62.21 1.329.499 2h2.019c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408z"/></svg></span></div>
                                    <form style="display: none">
                                        <input @change="subirJustificante(2)" type="file" name="inpJustificante" id="inpJustificante" accept=".jpeg,.jpg,.png,.bmp,.pdf,.tiff">
                                    </form>
                                    <p style="font-size: 0.688em;margin-top: 15px;">Todos los documentos serán destruidos una vez realizada la verificación de pago</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Premium',
    data: function(){
        return{
            tarifa: 0,
            pago: 0,
            cuenta: 'ES49 0081 0376 4500 0119 5021',
            numero: null,
            enviando: false
        }
    },
    methods: {
        pagar: function(){
            this.enviando = true;
            axios
                .get(process.env.VUE_APP_URL+`pay`, {
                    params:{
                        user: this.$store.state.user,
                        type: this.tarifa,
                        pay: this.pago
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        window.location = rs.data;
                    else
                        this.$parent.error = {
                            type: 2,
                            msg: rs.msg
                        };
                })
                .catch(error => {
                    this.$parent.error = {
                        type: 2,
                        msg: 'Algo salio mal'
                    };
                })
                .finally(() => this.enviando=false)
        },
        getNumero: function(){
            axios
                .get(process.env.VUE_APP_URL+`code`, {
                    params:{
                        token: this.$store.state.user
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.numero = rs.data;
                    }
                })
                .catch(error => this.$parent.error = {type: 2, msg: 'Algo ha salido mal'})
        },
        subirJustificante: function(mod){
            if(mod == 1){
                document.getElementById("inpJustificante").click();
            }else if(mod == 2){
                let formData = new FormData();
                let file = document.getElementById("inpJustificante").files[0];
                formData.append("documento", file);
                formData.append("user", this.$store.state.user);

                axios
                .post(process.env.VUE_APP_URL+`subir_justificante`, formData)
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                       this.$parent.error = {type: 1, msg: 'Justificante de pago subido con éxito'};
                    }
                })
                .catch(error => this.$parent.error = {type: 2, msg: 'Algo ha salido mal'})
            }
        },
    },
    created: function(){
        this.getNumero();
    }
}
</script>

<style>
    .premium-enter-active, .premium-leave-active {
        transition: opacity .5s;
    }
    .premium-enter, .premium-leave-to{
        opacity: 0;
    }
</style>