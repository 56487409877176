<template>
    <main class="static_content">
        <section class="semi_dinamic_container full_container state_vertical_center">
            <div class="film_sheet">
                <!--<div class="film_image_wrap">
                    <div class="video_player_wrapper" v-if="played">
                        <VideoPlayer :options="options"/>
                    </div>
                    <figure v-if="!played" class="centered_content content_rows full_height" :style="{'background-image': `url('https://vivex.tv/assets/slider/imgs/${pelicula.img}')`}">
                        <figcaption>
                            <div class="inner">
                                <div class="play_btn floating_element" @click="play()">
                                    <span class="circle"></span>
                                </div>
                            </div>
                        </figcaption>
                    </figure>
                </div>-->
                <div class="film_info height_inner">
                    <div class="film_info_inner">
                        <div class="breadcrumbs mb30">
                            <ul>
                                <li>
                                    <router-link :to="`/`" class="breadcrumbs_item">Home<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg>
                                    </router-link>
                                </li>
                                <li v-for="(familia, index) in pelicula.families" :key="index">
                                    <div @click="$router.push({name: 'Cartelera', params:{family: [familia.id]}})" class="breadcrumbs_item ">{{familia.name}}<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg>
                                    </div>
                                </li>
                                <li>
                                    <span class="breadcrumbs_item">{{pelicula.title}}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="film_info_wrapper">
                            <div class="film_info_container">
                                <div class="bookmark_film big" v-if="pelicula.favorite">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/></svg>
                                </div>
                                <h2>{{pelicula.title}}</h2>
                                <div class="film_bio">
                                    <div class="film_fast_data">
                                        <div class="film_fast_data_item"><img src="@/assets/images/ar_x.png" class="age_rating"></div>
                                        <div class="film_fast_data_item">{{pelicula.duration}} min</div>
                                        <div class="film_fast_data_item film_fast_data_link" @click="$router.push({name: 'Cartelera', params:{years: [pelicula.year]}})" :title="pelicula.year">{{pelicula.year}}</div>
                                        <div class="film_fast_data_item film_fast_data_link" @click="$router.push({name: 'Cartelera', params:{countries: [pelicula.country]}})" :title="pelicula.country">{{pelicula.country}}</div>
                                        <div class="film_fast_data_item film_fast_data_link" title="Pelicula X">Pelicula X</div>
                                        <div class="film_fast_data_item">Nº ICAA {{pelicula.icaa}}</div>
                                        <div class="film_fast_data_item"><span class="content_descriptor">SEXO</span></div>
                                    </div>
                                    <p class="film_desc">{{pelicula.description}}</p>
                                </div>


                                <table class="film_data">
                                    <tr>
                                        <td>
                                            <h3 class="fim_data_heading">Director</h3>
                                        </td>
                                        <td>
                                            <div class="common_link" @click="$router.push({name: 'Cartelera', params: {directors: [director.id]}})" v-for="(director, index) in pelicula.directors" :key="index" :title="director.name">
                                                {{director.name}}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h3 class="fim_data_heading">Reparto</h3>
                                        </td>
                                        <td>
                                            <div class="film_cast">
                                                <div class="common_link" @click="$router.push({name: 'Cartelera', params: {actors: [actor.id]}})" v-for="(actor, index) in pelicula.actors" :key="index" :title="actor.name">{{actor.name}}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h3 class="fim_data_heading">Categorias</h3>
                                        </td>
                                        <td>
                                            <div class="film_categories">
                                                <div class="common_link" @click="$router.push({name: 'Cartelera', params: {categories: [categoria.id]}})" :title="categoria.name" v-for="(categoria, index) in pelicula.categories" :key="index"><span>
                                                    {{categoria.name}}
                                                </span></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h3 class="fim_data_heading">Producido / distribuido por: </h3>
                                        </td>
                                        <td>
                                            <div class="common_link">
                                                <div class="distributor_logo" @click="$router.push({name: 'Cartelera', params:{producers: [productora.id]}})" v-for="(productora, index) in pelicula.producers" :key="index" :title="productora.name">
                                                    <img :src="`${imgdbUrl}producers/${productora.img}`">
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                <div class="film_data_nav">
                                    <div class="btn borderless big arrow right mr20 see_now_btn" @click="play()">
                                        Ver ahora<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"/></svg>
                                    </div>
                                    <div class="btn borderless big dark arrow right toggle_btn" :class="{selected: pelicula.favorite}" @click="favorite()">
                                        Favorita
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.5 10c-2.483 0-4.5 2.015-4.5 4.5s2.017 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.017-4.5-4.5-4.5zm2.5 5h-2v2h-1v-2h-2v-1h2v-2h1v2h2v1zm-6.527 4.593c-1.108 1.086-2.275 2.219-3.473 3.407-6.43-6.381-12-11.147-12-15.808 0-4.005 3.098-6.192 6.281-6.192 2.197 0 4.434 1.042 5.719 3.248 1.279-2.195 3.521-3.238 5.726-3.238 3.177 0 6.274 2.171 6.274 6.182 0 .746-.156 1.496-.423 2.253-.527-.427-1.124-.768-1.769-1.014.122-.425.192-.839.192-1.239 0-2.873-2.216-4.182-4.274-4.182-3.257 0-4.976 3.475-5.726 5.021-.747-1.54-2.484-5.03-5.72-5.031-2.315-.001-4.28 1.516-4.28 4.192 0 3.442 4.742 7.85 10 13l2.109-2.064c.376.557.839 1.048 1.364 1.465z"/></svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.5 10c-2.483 0-4.5 2.015-4.5 4.5s2.017 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.017-4.5-4.5-4.5zm2.5 5h-5v-1h5v1zm-6.527 4.593c-1.108 1.086-2.275 2.219-3.473 3.407-6.43-6.381-12-11.147-12-15.808 0-6.769 8.852-8.346 12-2.944 3.125-5.362 12-3.848 12 2.944 0 .746-.156 1.496-.423 2.253-1.116-.902-2.534-1.445-4.077-1.445-3.584 0-6.5 2.916-6.5 6.5 0 2.063.97 3.901 2.473 5.093z"/></svg>
                                    </div>
                                </div>

                            </div>
                            <div id="film_video_container" class="film_video_container" v-if="played">
                                <VideoPlayer :options="options" :histInterval="histInterval"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="loop_player">
                <video v-if="!played && imgdbUrl" :key="pelicula.url" loop="" muted="muted" :poster="`${imgdbUrl}posters/poster_${pelicula.url}.jpg`" playsinline="" autoplay>
                    <source :src="`${imgdbUrl}shorts/short_${pelicula.url}.mp4`" media="(max-width: 960px)" type="video/mp4">
                </video>
                <div class="loop_static" v-else :style="{'background-image': `url('${imgdbUrl}posters/poster_${pelicula.url}.jpg')`}"></div>
            </div>
        </section>
    </main>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer.vue'
import axios from 'axios';

export default {
    name: 'Pelicula',
    props: {
        pelicula: {
            type: Object,
            require: true
        }
    },
    components:{
        VideoPlayer
    },
    data: function(){
        return{
            played: false,
            options: null,
            imgdbUrl: null,
            histInterval: null
        }
    },
    methods:{
        tracker: function(){
            this.histInterval = setInterval( ()=> {
                if(document.getElementsByTagName("video").length == 1 && this.played ){
                    axios.post(process.env.VUE_APP_URL+`historial`, {
                        user: this.$store.state.user,
                        film: this.pelicula.id,
                        time: document.getElementsByTagName("video")[0].currentTime
                    });
                }
            }, 6000);
        },
        play: function(){
            if(!this.played){
                this.options = {
                    autoplay: true,
                    controls: true,
                    preload: false,
                    responsive: true,
                    currentTime: this.$route.query.t ? this.$route.query.t : 0,
                    //width: 640,
                    //fluid: true,
                    aspectRatio: '16:9',
                    poster: `${this.imgdbUrl}posters/poster_${this.pelicula.url}.jpg`,
                    sources: [

                        {
                            src: `${this.imgdbUrl}films/film_${this.pelicula.url}/main.m3u8`,
                            type: "application/x-mpegURL"
                        }
                    ]
                };
                this.played = true;
                this.tracker();
            }else if(document.getElementsByClassName("video-js").length == 1){
                    var video = document.getElementsByClassName("video-js")[0];
                    if (video.requestFullscreen)
                        video.requestFullscreen();
                    else if (video.webkitRequestFullscreen)
                        video.webkitRequestFullscreen();
                    else if (video.msRequestFullScreen)
                        video.msRequestFullScreen();
            }
        },
        favorite: function(){
            axios
                .post(process.env.VUE_APP_URL+`favorites`, {
                    user: this.$store.state.user,
                    film: this.pelicula.id
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        if(this.pelicula.favorite)
                            this.$parent.error = {type: 1, msg: 'Eliminado de favoritos'}
                        else
                            this.$parent.error = {type: 1, msg: 'Agregado a favoritos'}
                        this.$parent.getPelicula();
                    }
                    else
                        this.$parent.error = {type: 2, msg: rs.msg}
                })
                .catch(error => {
                    this.$parent.error = {type: 2, msg: 'Algo salio mal'};
                })
        }
    },
    mounted: function(){
        this.imgdbUrl = process.env.VUE_APP_URL_IMGDB;
        if(this.$route.query.t) this.play();
    }
}
</script>