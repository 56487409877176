import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video

Vue.prototype.$response_error = function(error){
  if(error.response){
      if(error.response.status == 401){
        this.$store.commit('logout');
        window.location.replace("/");
      }
      else if(error.response.status == 403 && this.$route.path !== '/cuenta')
          this.$router.push('/cuenta');
    
      return Promise.reject(error)
  }
};


import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  beforeCreate() {
		this.$store.commit('initialStore');
	},
  render: function (h) { return h(App) }
}).$mount('#app')

import '@/assets/style.css'