<template>
    <main class="static_content billboard_pad">
        <div class="film_list_header">
            <div class="filter_tag_list">
                <div class="filter_tag category" v-if="familias.length > 0">
                    <span>/</span>{{nameFamily}}
                </div>
                <!--<div class="filter_tag actor" v-for="(actor, index) in actores" :key="index">
                    <span>{{actoresNames[index]}}</span>
                    <svg @click="actores.splice(actores.indexOf(actor), 1)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"></path></svg>
                </div>
                <div class="filter_tag director" v-for="(director, index) in directores" :key="index">
                    {{getName(director, 1)}}<svg @click="directores.splice(directores.indexOf(director), 1)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"></path></svg>
                </div>
                <div class="filter_tag productora" v-for="(productor, index) in productores" :key="index">
                    {{getName(productor, 5)}}<svg @click="productores.splice(productores.indexOf(productor), 1)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"></path></svg>
                </div>
                <div class="filter_tag categoria" v-for="(categoria, index) in categorias" :key="index">
                    {{getName(categoria, 2)}}<svg @click="categorias.splice(categorias.indexOf(categoria), 1)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"></path></svg>
                </div>
                <div class="filter_tag year" v-for="(year, index) in anos" :key="index">
                    {{year}}<svg @click="anos.splice(anos.indexOf(year), 1)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"></path></svg>
                </div>
                <div class="filter_tag country" v-for="(pais, index) in paises" :key="index">
                    {{pais}}<svg @click="paises.splice(paises.indexOf(pais), 1)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"></path></svg>
                </div>-->
            </div>
            <div class="filter_tag_trigger" :class="{open: filtros, filters_active: (familias.length>0 || categorias.length>0 || directores.length>0 || actores.length>0 || anos.length>0 || paises.length>0 || productores.length>0)}" @click="filtros=!filtros">
                Filtros
                <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg></span>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 10h24v4h-24z"/></svg></span>
            </div>
            <Filtros :open="filtros" :random="random"/>
        </div>
        <section class="slider_container category_slider" v-if="familias.length && lasts">
            <div class="loader grey_dark" :class="{visible: lasts.length<=0}"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
            <VitySlide 
                :dat="lasts"
                imgRatio="45%"
                :arrows="false"
                @click="(film) => {$router.push(`/film/${film}`)}"
            />
        </section>
        <section class="film_list visible">
            <div class="loader grey_dark" :class="{visible: peliculas.length<=0}"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
            <transition-group tag="div" class="film_list_wrapper">
                <PeliculaCartelera :pelicula="pelicula" v-for="(pelicula, index) in peliculas" :key="index"/>
            </transition-group>
            <div class="more_vid_btn" @click="getPelis()" :class="{loading: loading}">
                <div class="loader black"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                <span>Cargar más vídeos</span>
            </div>
        </section>
    </main>
</template>

<script>
import PeliculaCartelera from '@/components/PeliculaCartelera.vue';
import Filtros from '@/components/Cartelera/Filtros.vue';
import VitySlide from '@/components/VitySlides.vue';
import axios from 'axios';

export default {
    name: 'cartelera',
    components: {
        PeliculaCartelera,
        Filtros,
        VitySlide
    },
    data: function(){
        return{
            peliculas: [],
            familias: [],
            categorias: [],
            directores: [],
            actores: [],
            //actoresNames: [],
            anos: [],
            paises: [],
            productores: [],

            lasts: [],

            //PARA FILTROS
            years: [],
            titulo: null,
            filtros: false,
            random: 0,

            loading: false,
            nameFamily: null
        }
    },
    methods: {
        reset: function(){
            this.peliculas = [];
            this.familias = [];
            this.categorias = [];
            this.directores = [];
            this.actores = [];
            this.anos = [];
            this.paises = [];
            this.productores = [];
        },
        getPelis: function(reset=0){
            this.random = Math.random();
            if(reset)
                this.peliculas = [];
            else
                this.loading = true;
            let params = {
                user: this.$store.state.user,
                limit: 12,
                offset: reset ? 0 : this.peliculas.length,
                families: this.familias,
                categories: this.categorias,
                directors: this.directores,
                actors: this.actores,
                years: this.anos,
                countries: this.paises,
                producers: this.productores,
                'orderBy[column]': 'actived_at',
                'orderBy[order]': 'desc',
            };
            if(this.titulo){
                params = Object.assign(
                    params, 
                    {search: this.titulo}, 
                    {columns: this.titulo ? [{searchable: true, dbName: 'title'}] : null}
                );
            }

            axios
                .get(process.env.VUE_APP_URL+`films`, {
                    params: params
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        if(reset)
                            this.peliculas = rs.data;
                        else
                            this.peliculas = this.peliculas.concat(rs.data);
                    }
                })
                .catch(error => this.$response_error(error))
                .finally(() => this.loading = false)
        },
        getUltimas: function(){
            this.lasts = [];
            axios
                .get(process.env.VUE_APP_URL+`films`, {
                    params:{
                        user: this.$store.state.user,
                        limit: 3,
                        families: this.familias,
                        'orderBy[column]': 'actived_at',
                        'orderBy[order]': 'desc'
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        let temp = [];
                        rs.data.forEach(element => {
                            temp.push({
                                title: element.title,
                                subtitle: element.title,
                                img: `${process.env.VUE_APP_URL_IMGDB}posters/poster_${element.url}.jpg`,
                                returnData: element.id
                            });
                        });
                        this.lasts = temp;
                    }
                })
                .catch(error => this.$response_error(error))
        },
        getName: async function(id, type){
            let url;
            switch(type){
                case 0:
                    url = `actors/${id}`;
                    break;
                case 1:
                    url = `directors/${id}`;
                    break;
                case 2:
                    url = `categories/${id}`;
                    break;
                case 5:
                    url = `producers/${id}`;
                    break;
                case 6:
                    url = `families/${id}`;
                    break;
            }
            await axios
                .get(process.env.VUE_APP_URL+url, {
                    params:{
                        user: this.$store.state.user
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        //this.actoresNames.push(rs.data.name);
                        //return rs.data.name;
                        this.nameFamily = rs.data.name;
                })
                .catch(error => this.$response_error(error))
        },
        init: function(){
            if(this.$route.params.families)
                this.familias = this.$route.params.families;
            if(this.$route.params.categories)
                this.categorias = this.$route.params.categories;
            if(this.$route.params.directors)
                this.directores = this.$route.params.directors;
            if(this.$route.params.actors)
                this.actores = this.$route.params.actors;
            if(this.$route.params.years)
                this.anos = this.$route.params.years;
            if(this.$route.params.countries)
                this.paises = this.$route.params.countries;
            if(this.$route.params.producers)
                this.productores = this.$route.params.producers;
        }
    },
    created: function(){
        this.init();
        this.getPelis(1);
        this.getUltimas();
        if(this.$route.params.family)
            this.familias = this.$route.params.family;
    },
    watch: {
        /*$route: function(){
            this.init();
        },*/
        titulo: function(){
            this.getPelis(1);
        },
        familias: function(){
            this.getPelis(1);
            this.getUltimas();
            this.getName(this.familias[0], 6);
        },
        categorias: function(){
            this.getPelis(1);
        },
        directores: function(){
            this.getPelis(1);
        },
        actores: function(){
            //this.actoresNames = [];
            this.getPelis(1);
            /*this.actores.forEach(element => {
                this.getName(element, 0);
            });*/
        },
        anos: function(){
            this.getPelis(1);
        },
        paises: function(){
            this.getPelis(1);
        },
        productores: function(){
            this.getPelis(1);
        }
    }
}
</script>