<template>
    <div class="dinamic_container full_container state_center">
        <div class="floating_content">
            <div class="inner">
                <div class="box">
                    <div class="box_block w50 offsetw25 login_block floating_element floating_element_a">
                        <div class="h2">Recupera aquí tu contraseña</div>
                        <div class="form">
                            <div class="input_block">
                                <input type="password" v-model="password" ref="password1" placeholder="Contraseña nueva" tabindex="0" class="fixed">
                            </div>
                            <div class="input_block">
                                <input type="password" v-model="password2" ref="password1" placeholder="Repetir contraseña nueva" tabindex="0" class="fixed">
                            </div>
                            <div class="input_block">
                                <div @click="recuperarPassword" class="btn wide big login_btn down"><span>Enviar</span></div>
                            </div>
                            <div class="input_block" @click="$router.push('/login')">
                                <div class="simple_link dark icon_link" id="volverALoginLbl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3.222 18.917c5.666-5.905-.629-10.828-5.011-7.706l1.789 1.789h-6v-6l1.832 1.832c7.846-6.07 16.212 4.479 7.39 10.085z"/></svg>Ir a Login</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Recuperar',
    data: function(){
        return{
            password: null,
            password2: null
        }
    },
    methods:{
        recuperarPassword: function(){
            if(!this.password || !this.password2){
                this.$parent.$parent.error = {type: 2, msg: 'Introduce las dos contraseñas'};
                return;
            }
            if(this.password !== this.password2){
                this.$parent.$parent.error = {type: 2, msg: 'Las contraseñas no coinciden'};
                return;
            }
            axios
                .post(process.env.VUE_APP_URL+`recover_password_2`, {
                    password: this.password,
                    token: this.$route.params.token
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == "OK"){
                        this.$parent.$parent.error = {type: 1, msg: 'Contraseña cambiada con exito'};
                        $router.push('/login');
                    }
                    else{
                        this.$parent.$parent.error = {type: 2, msg: rs.msg};
                    }
                })
                .catch(error => this.$response_error(error))
        }
    },
}
</script>