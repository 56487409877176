<template>
    <div>
        <h2>PREMIUM</h2>
        <div class="input_block">
            <table class="table premium_status" border="0" cellpadding="0" cellspacing="0">
                <tr>
                    <td>Tú tipo de suscripción Premium</td>
                    <td><b class="code_type">{{premiums.length > 1 ? getTipo(premiums[0].type):''}}</b></td>
                </tr>
                <tr>
                    <td>Tú código de suscripción actual es</td>
                    <td><b class="premium_code">{{number}}</b></td>
                </tr>
            </table>
            <div class="btn small wide gold" @click="$router.push('/premium')">Cambiar de suscripción</div>
        </div>
        <div class="table_wrapper">
            <table class="table premium" border="0" cellpadding="0" cellspacing="0">
                <tr>
                    <th>Tipo de suscripción</th>
                    <th>Fecha alta</th>
                    <th>Fecha de caducidad</th>
                    <th>Estatus</th>
                </tr>
                <tr class="Clases" v-for="(premium, index) in premiums" :key="index">
                    <td>{{getTipo(premium.type)}}</td>
                    <td>{{getFecha(premium.updated_at)}}</td>
                    <td>{{premium.deleted_at ? getFecha(premium.deleted_at) : '-'}}</td>
                    <td>{{getStatus(premium)}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Premium',
    props:{
        premiums: {
            type: Array
        },
        number: {
            type: String
        }
    },
    methods:{
        getTipo: function(tipo){
            switch(tipo){
                case 1:
                    return 'Hora de registro';
                case 2:
                    return '48 horas de cortesía';
                case 3:
                    return 'Mensual';
                case 4:
                    return 'Anual';
            }
        },
        getStatus: function(premium){
            if(!premium.deleted_at)
                return 'Pendiente';
            else if(new Date(premium.deleted_at) > new Date())
                return 'Activa';
            else
                return 'Caducada';
        },
        getFecha: function(fecha){
            let final = new Date(fecha);

            return `${String(final.getDate()).padStart(2, '0')}/${String(final.getMonth()+1).padStart(2, '0')}/${final.getFullYear()}`;
        }
    }
}
</script>