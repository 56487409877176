<template>
    <div class="film_list_item" @click="$router.push(`/film/${pelicula.id}`)" @mouseenter="ver=true" @mouseleave="ver=false">
        <div class="bookmark_film small" v-if="pelicula.favorite">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/></svg>
        </div>
        <div class="film_list_info">
            <div class="animated_title floating_element_slow">
                <div>
                    <span>{{pelicula.title}}</span>
                    <span>{{pelicula.title}}</span>
                </div>
            </div>
        </div>
        <div class="film_list_item_img floating_element floating_element_PeliAnim" :style="{'background-image': `url('${imgdbUrl}thumbs/thumb_${pelicula.url}.jpg')`}"></div>
        <div v-if="ver" class="film_list_item_loop">
            <video loop="" muted="muted" poster="" playsinline="" autoplay>
                <source :src="`${imgdbUrl}shorts/short_${pelicula.url}.mp4`" media="(max-width: 960px)" type="video/mp4">
            </video>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PeliculaCartelera',
    props: {
        pelicula: {
            type: Object,
            default: function(){
                return {}
            }
        }
    },
    data: function(){
        return{
            imgdbUrl: null,
            ver: false
        }
    },
    mounted: function(){
        this.imgdbUrl = process.env.VUE_APP_URL_IMGDB;
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    }
}
</script>