<template>
    <div id="app">
        <div class="cookies_disclaimer" v-if="!$store.state.cookies">
            <div class="cookies_disclaimer_wrapper">
                <div class="cookies_text">
                    <p>En VIVEX.TV No utilizamos ningún tipo de cookie ni tampoco almacenamos ni compartimos datos personales o de navegación.</p>
                    <p>Estas en una web 100% limpia</p>
                    <router-link to="/legal" title="cookies">Más información</router-link>
                </div>
                <div class="cookies_cta">
                    <div class="close_button" @click="setCookies()">Acepto</div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <div class="message" @click="error=null" :class="{visible: error && error.type > 0, ok: error && error.type==1, ko: error && error.type==2}">
            <h3>{{error ? error.msg : null}}</h3>
        </div>

        <transition :name="transitionName" mode="out-in">
            <Modal v-if="modal"/>
        </transition>

        <header>
            <div class="search_wrapper" :class="{show: buscar}" v-if="user">
                <div class="close_btn" @click="() => {buscar=false;result=null;busqueda=null;}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
                </div>
                <div class="search_engine">
                    <input ref="search" type="text" v-model="busqueda" placeholder="Búsqueda por título, director, actores, etc">
                    <div class="nav_search_list" v-if="result">
                        <div class="nav_search_list_result" v-if="result.films.length">
                            <div class="search_family_label">Películas</div>
                            <div class="nav_search_list_link" @click="$router.push(`/film/${result.id}`)" v-for="(result, index) in result.films" :key="index">{{result.title}}</div>
                        </div>
                        <div class="nav_search_list_result" v-if="result.actors.length">
                            <div class="search_family_label">Actores</div>
                            <div class="nav_search_list_link" @click="goSearch('actors', result.id)" v-for="(result, index) in result.actors" :key="index">{{result.name}}</div>
                        </div>
                        <div class="nav_search_list_result" v-if="result.directors.length">
                            <div class="search_family_label">Directores</div>
                            <div class="nav_search_list_link" @click="goSearch('directors', result.id)" v-for="(result, index) in result.directors" :key="index">{{result.name}}</div>
                        </div>
                        <div class="nav_search_list_result" v-if="result.categories.length">
                            <div class="search_family_label">Categorias</div>
                            <div class="nav_search_list_link" @click="goSearch('categories', result.id)" v-for="(result, index) in result.categories" :key="index">{{result.name}}</div>
                        </div>
                        <div class="nav_search_list_result" v-if="result.families.length">
                            <div class="search_family_label">Familias</div>
                            <div class="nav_search_list_link" @click="goSearch('families', result.id)" v-for="(result, index) in result.families" :key="index">{{result.name}}</div>
                        </div>
                        <div class="nav_search_list_result" v-if="result.producers.length">
                            <div class="search_family_label">Productoras</div>
                            <div class="nav_search_list_link" @click="goSearch('producers', result.id)" v-for="(result, index) in result.producers" :key="index">{{result.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header_wrapper" :class="{menu_visible: menu, landing_visible: !menu && route == 5}"><!--class=" @auth user_in @endauth"-->
                <div class="header_block menu_open" @click="$router.push('/')">
                    <div class="logo home_cta dark">
                        <div class="logo_anim">
                            <span>
                                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 328.8189 108.8532">
                                    <path class="cls-1" d="M0,30.1534H8.594L37.6919,98.7521l29.4-68.5987h8.2922L40.8579,107.648h-6.332Z"/>
                                    <path class="cls-1" d="M84.4278,0h9.1968V10.1011H84.4278Zm.9045,30.1535H92.72v76.8909H85.3323Z"/>
                                    <path class="cls-1" d="M102.67,30.1534h8.5941l29.0978,68.5987,29.4-68.5987h8.2922L143.5275,107.648h-6.332Z"/>
                                    <path class="cls-1" d="M219.4487,108.8533a37.183,37.183,0,0,1-14.4309-2.8642,36.5764,36.5764,0,0,1-12.09-8.141,38.516,38.516,0,0,1-8.2353-12.6646A42.9766,42.9766,0,0,1,181.67,68.75v-.3014a43.3882,43.3882,0,0,1,2.7886-15.6043,40.13,40.13,0,0,1,7.69-12.74A36.617,36.617,0,0,1,203.6819,31.51a33.1607,33.1607,0,0,1,14.474-3.166,33.5936,33.5936,0,0,1,14.6243,3.09,32.9613,32.9613,0,0,1,11.0818,8.5185,38.8564,38.8564,0,0,1,7.0859,12.74,49.1171,49.1171,0,0,1,2.4873,15.9062v1.5833a9.5051,9.5051,0,0,1-.15,1.4321H189.51a33.4651,33.4651,0,0,0,3.1754,12.7514,33.0163,33.0163,0,0,0,6.8012,9.4514,28.0177,28.0177,0,0,0,9.2983,5.9272,30.009,30.009,0,0,0,10.9581,2.023q9.0683,0,15.3438-3.4679a45.2314,45.2314,0,0,0,11.1112-8.593l5.1267,4.5225a53.6136,53.6136,0,0,1-13.3639,10.4781Q230.48,108.8534,219.4487,108.8533Zm26.1467-43.8727a43.8213,43.8213,0,0,0-2.3439-11.232,31.3107,31.3107,0,0,0-5.2906-9.4985,25.3451,25.3451,0,0,0-8.3914-6.6339,27.5465,27.5465,0,0,0-22.3727-.2258,26.8581,26.8581,0,0,0-8.6926,6.2565,33.8922,33.8922,0,0,0-6.123,9.4985A35.5085,35.5085,0,0,0,189.51,64.9806Z"/>
                                    <path class="cls-1" d="M287.9605,67.8453,257.6568,30.1534h9.1963l25.9318,32.5657,25.9328-32.5657h8.8949L297.3079,67.6946l31.511,39.35h-9.1972L292.4835,72.8208l-27.1381,34.2235H256.45Z"/></svg>
                            </span>
                            <span>
                                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 328.8189 108.8532">
                                    <path class="cls-1" d="M0,30.1534H8.594L37.6919,98.7521l29.4-68.5987h8.2922L40.8579,107.648h-6.332Z"/>
                                    <path class="cls-1" d="M84.4278,0h9.1968V10.1011H84.4278Zm.9045,30.1535H92.72v76.8909H85.3323Z"/>
                                    <path class="cls-1" d="M102.67,30.1534h8.5941l29.0978,68.5987,29.4-68.5987h8.2922L143.5275,107.648h-6.332Z"/>
                                    <path class="cls-1" d="M219.4487,108.8533a37.183,37.183,0,0,1-14.4309-2.8642,36.5764,36.5764,0,0,1-12.09-8.141,38.516,38.516,0,0,1-8.2353-12.6646A42.9766,42.9766,0,0,1,181.67,68.75v-.3014a43.3882,43.3882,0,0,1,2.7886-15.6043,40.13,40.13,0,0,1,7.69-12.74A36.617,36.617,0,0,1,203.6819,31.51a33.1607,33.1607,0,0,1,14.474-3.166,33.5936,33.5936,0,0,1,14.6243,3.09,32.9613,32.9613,0,0,1,11.0818,8.5185,38.8564,38.8564,0,0,1,7.0859,12.74,49.1171,49.1171,0,0,1,2.4873,15.9062v1.5833a9.5051,9.5051,0,0,1-.15,1.4321H189.51a33.4651,33.4651,0,0,0,3.1754,12.7514,33.0163,33.0163,0,0,0,6.8012,9.4514,28.0177,28.0177,0,0,0,9.2983,5.9272,30.009,30.009,0,0,0,10.9581,2.023q9.0683,0,15.3438-3.4679a45.2314,45.2314,0,0,0,11.1112-8.593l5.1267,4.5225a53.6136,53.6136,0,0,1-13.3639,10.4781Q230.48,108.8534,219.4487,108.8533Zm26.1467-43.8727a43.8213,43.8213,0,0,0-2.3439-11.232,31.3107,31.3107,0,0,0-5.2906-9.4985,25.3451,25.3451,0,0,0-8.3914-6.6339,27.5465,27.5465,0,0,0-22.3727-.2258,26.8581,26.8581,0,0,0-8.6926,6.2565,33.8922,33.8922,0,0,0-6.123,9.4985A35.5085,35.5085,0,0,0,189.51,64.9806Z"/>
                                    <path class="cls-1" d="M287.9605,67.8453,257.6568,30.1534h9.1963l25.9318,32.5657,25.9328-32.5657h8.8949L297.3079,67.6946l31.511,39.35h-9.1972L292.4835,72.8208l-27.1381,34.2235H256.45Z"/></svg>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="header_block right">
                    <div class="navigation">
                        <div class="nav_btn" @click="$router.push('/login')" v-if="!user && $route.name != 'Login'">
                            <div class="animated_title">
                                <div>
                                    <span>Iniciar sesión</span>
                                    <span>Iniciar sesión</span>
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.625 8.292c0 .506-.41.917-.917.917s-.916-.411-.916-.917.409-.917.916-.917.917.411.917.917zm7.375 3.708c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-11.293 1.946c-1.142-.436-2.065-1.312-2.561-2.423l-3.146 3.185v2.292h3v-1h1v-1h.672l1.035-1.054zm5.293-4.279c0-2.025-1.642-3.667-3.667-3.667-2.024 0-3.666 1.642-3.666 3.667s1.642 3.667 3.666 3.667c2.025-.001 3.667-1.643 3.667-3.667z"/></svg>
                        </div>
                        <div class="nav_btn" @click="$router.push('/registro')" v-if="!user && $route.name == 'Login'">
                            <div class="animated_title">
                                <div>
                                    <span>Regístrate</span>
                                    <span>Regístrate</span>
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.994 9.095l2.974-2.884c.144-.14.331-.211.516-.211.188 0 .375.073.518.22l-4.032 3.911c-.283-.294-.268-.752.024-1.036zm-4.49 8.819c-.06.057-.136.086-.212.086-.168 0-.304-.137-.304-.304 0-.079.031-.159.093-.218l.5-.485.422.436-.499.485zm4.034-2.386c-.919.891-1.796 1.333-3.013 1.728l-.754-.779c.433-1.205.901-2.067 1.819-2.958l1.71-1.657 1.946 2.009-1.708 1.657zm6.965-6.483l-4.402 4.269-2.218-2.29 4.402-4.27c1.016-.984 2.703-.246 2.703 1.146 0 .416-.162.832-.485 1.145z"/></svg>
                        </div>
                        <div class="nav_btn search_btn" @click="() => {buscar=true;$refs.search.focus()}" v-if="user">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z"/></svg>
                        </div>
                    </div>
                    <div class="menu_btn" :class="{shut_btn: menu}" @click="menu=!menu">
                        <span class="bar_01"></span>
                        <span class="bar_02"></span>
                        <span class="bar_03"></span>
                    </div>
                </div>
            </div>
        </header>
        <div class="wrapper mobile_focus">
            <div v-if="!user" id="video_home" class="video_home" style="z-index: 0;">
                <div class="video_home_wrapper">
                    <div class="video_home_container">
                        <video id="videoCabecera" preload="none" autoplay="autoplay" muted="muted" loop="loop" style="top: 0%;">
                            <source src="@/assets/images/home_video.mp4" type="video/mp4"></video>
                    </div>
                </div>
            </div>
            <div class="dinamic_menu" :class="{visible: menu}">

                <div class="floating_content">
                    <div class="inner">
                        <div class="box box_column">
                            <div class="box_block w100">
                                <nav class="navigation big_nav" v-if="user">
                                    <ul>
                                        <li class="floating_item floating_item_a">
                                            <div  @click="() => {route = 5; $router.push('/')}" class="nav_link" :class="{actual: this.$route.path == '/'}">Inicio</div>
                                        </li>
                                        <li class="floating_item floating_item_b">
                                            <div @click="$router.push('/novedades')" class="nav_link" :class="{actual: this.$route.path == '/novedades'}">Novedades</div>
                                        </li>
                                        <li class="floating_item floating_item_c">
                                            <div @click="$router.push('/cartelera')" class="nav_link" :class="{actual: this.$route.path == '/cartelera'}">Cartelera</div>
                                        </li>
                                    </ul>
                                </nav>
                                <nav class="navigation medium_nav" v-if="user">
                                    <ul>
                                        <li class="floating_item floating_item_d">
                                            <div @click="$router.push('/cuenta')" class="nav_link" :class="{actual: this.$route.path == '/cuenta'}">Mi cuenta</div>
                                        </li>
                                    </ul>
                                </nav>
                                <nav class="navigation medium_nav" v-if="!user">
                                    <ul>
                                        <li class="floating_item floating_item_a">
                                            <div @click="$router.push('/registro')" class="nav_link" :class="{actual: this.$route.path == '/registro'}">Registro</div>
                                        </li>
                                        <li class="floating_item floating_item_b">
                                            <div @click="$router.push('/login')" class="nav_link" :class="{actual: this.$route.path == '/login'}">Iniciar sesión</div>
                                        </li>
                                    </ul>
                                </nav>
                                <nav class="navigation medium_nav">
                                    <ul>
                                        <li class="floating_item floating_item_f">
                                            <div @click="$router.push('/about')" class="nav_link whoBtn" :class="{actual: this.$route.path == '/about'}">Quiénes somos</div>
                                        </li>
                                    </ul>
                                </nav>
                                <nav class="navigation small_nav">
                                    <ul>
                                        <li class="floating_item floating_item_g">
                                            <div @click="$router.push('/contacto')" class="nav_link" :class="{actual: this.$route.path == '/contacto'}">Contacto</div>
                                        </li>
                                    </ul>
                                </nav>
                                <nav class="navigation small_nav" v-if="user">
                                    <ul>
                                        <li class="floating_item floating_item_h">
                                            <div @click="logout()" class="nav_link">Logout</div>
                                        </li>
                                    </ul>
                                </nav>
                                <footer class="footer">
                                    <div class="footer_content floating_item floating_item_g">
                                        <p class="legal">
                                            <b style="font-size: 1.375em">vivex</b> es una marca de <a href="https://www.apeoga.es" target="_new">APEOGA</a> (Asociación de Productores y Editores de Obras y Grabaciones Audiovisuales para adultos). | <router-link to="/legal">Términos y condiciones</router-link>
                                        </p>
                                    </div>
                                </footer>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mega_text">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 328.8189 108.8532">
                    <path class="cls-1" d="M0,30.1534H8.594L37.6919,98.7521l29.4-68.5987h8.2922L40.8579,107.648h-6.332Z"/>
                    <path class="cls-1" d="M84.4278,0h9.1968V10.1011H84.4278Zm.9045,30.1535H92.72v76.8909H85.3323Z"/>
                    <path class="cls-1" d="M102.67,30.1534h8.5941l29.0978,68.5987,29.4-68.5987h8.2922L143.5275,107.648h-6.332Z"/>
                    <path class="cls-1" d="M219.4487,108.8533a37.183,37.183,0,0,1-14.4309-2.8642,36.5764,36.5764,0,0,1-12.09-8.141,38.516,38.516,0,0,1-8.2353-12.6646A42.9766,42.9766,0,0,1,181.67,68.75v-.3014a43.3882,43.3882,0,0,1,2.7886-15.6043,40.13,40.13,0,0,1,7.69-12.74A36.617,36.617,0,0,1,203.6819,31.51a33.1607,33.1607,0,0,1,14.474-3.166,33.5936,33.5936,0,0,1,14.6243,3.09,32.9613,32.9613,0,0,1,11.0818,8.5185,38.8564,38.8564,0,0,1,7.0859,12.74,49.1171,49.1171,0,0,1,2.4873,15.9062v1.5833a9.5051,9.5051,0,0,1-.15,1.4321H189.51a33.4651,33.4651,0,0,0,3.1754,12.7514,33.0163,33.0163,0,0,0,6.8012,9.4514,28.0177,28.0177,0,0,0,9.2983,5.9272,30.009,30.009,0,0,0,10.9581,2.023q9.0683,0,15.3438-3.4679a45.2314,45.2314,0,0,0,11.1112-8.593l5.1267,4.5225a53.6136,53.6136,0,0,1-13.3639,10.4781Q230.48,108.8534,219.4487,108.8533Zm26.1467-43.8727a43.8213,43.8213,0,0,0-2.3439-11.232,31.3107,31.3107,0,0,0-5.2906-9.4985,25.3451,25.3451,0,0,0-8.3914-6.6339,27.5465,27.5465,0,0,0-22.3727-.2258,26.8581,26.8581,0,0,0-8.6926,6.2565,33.8922,33.8922,0,0,0-6.123,9.4985A35.5085,35.5085,0,0,0,189.51,64.9806Z"/>
                    <path class="cls-1" d="M287.9605,67.8453,257.6568,30.1534h9.1963l25.9318,32.5657,25.9328-32.5657h8.8949L297.3079,67.6946l31.511,39.35h-9.1972L292.4835,72.8208l-27.1381,34.2235H256.45Z"/></svg>
                </div>
            </div>
            <transition :name="transitionName" mode="out-in">
                <Externa v-if="!user && route == 1"/>
                <Legal v-else-if="route == 2"/>
                <Contacto v-else-if="route == 3"/>
                <About v-else-if="route == 4"/>
                <Landing v-else-if="user && familias && route == 5" :familias="familias"/>
                <Cartelera v-else-if="user && route == 6" ref="cartelera"/>
                <Pelicula :key="pelicula" :pelicula="pelicula" v-else-if="user && pelicula && route == 7"/>
                <Cuenta v-else-if="user && route == 8"/>
                <Novedades v-else-if="user && route == 9"/>
                <Premium v-else-if="user && route == 10"/>

                <router-view v-else/>
            </transition>
        </div>

    </div>
</template>

<script>
import Landing from '@/views/Landing.vue';
import Legal from '@/views/Legal.vue';
import Contacto from '@/views/Contacto.vue';
import About from '@/views/About.vue';
import Externa from '@/views/Externa.vue';
import Cartelera from '@/views/Cartelera.vue';
import Pelicula from '@/views/Pelicula.vue';
import Cuenta from '@/views/Cuenta.vue';
import Novedades from '@/views/Novedades.vue';
import Premium from '@/views/Premium.vue';
import axios from 'axios';

export default {
    name: 'App',
    components: {
        Landing,
        Legal,
        Contacto,
        About,
        Externa,
        Cartelera,
        Pelicula,
        Cuenta,
        Novedades,
        Premium
    },
    data: function(){
        return {
            /** 1 -> Index Out
             *  2 -> Legal
             *  3 -> Contacto
             *  4 -> About
             *  5 -> Landing
             *  6 -> Novedades
             *  7 -> Pelicula
             *  8 -> Cuenta
             *  9 -> Novedades
             *  10 -> Premium
             */
            route: 0,
            transitionName: 'fade',
            error: null,
            modal: false,

            user: null,
            menu: false,

            pelicula: null,

            familias: [],

            buscar: false,
            busqueda: null,
            result: null
        }
    },
    methods: {
        init: function(){
            // this.deleteAllCookies();
            if(!this.$route.name){
                setTimeout(() => this.init(), 100);
                return;
            }
            this.user = this.$store.state.user;
            this.menu = false;
            switch(this.$route.name){
                case 'Legal':
                    this.route = 2;
                    break;
                case 'Contacto':
                    this.route = 3;
                    break;
                case 'About':
                    this.route = 4;
                    break;
                case 'Cartelera':
                    this.route = 6;
                    break;
                case 'Pelicula':
                    this.getPelicula();
                    break;
                case 'Cuenta':
                    this.route = 8;
                    break;
                case 'Novedades':
                    this.route = 9;
                    break;
                case 'Premium':
                    this.route = 10;
                    break;
                default:
                case 'Index':
                    if(!this.user)
                        this.route = 1;
                    else{
                        if(!this.$store.state.landing || this.route == 5)//MUESTRA LANDING
                            this.getFamilias();
                        else
                            this.$router.push(`/cartelera`);
                    }
                    break;
            }
            if(this.route > 4 && !this.user)
                this.logout();
        },
        logout: function(){
            axios
                .post(process.env.VUE_APP_URL+`logout`, {
                    token: this.$store.state.user
                })
                .finally(() => {
                    this.$store.commit('logout');
                    window.location.replace("/");
                })
        },
        setCookies: function(){
            this.$store.commit('cookies');
        },
        deleteAllCookies: function(){
            var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var eqPos = cookie.indexOf("=");
                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;domain=";
                document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;domain=" + location.hostname.replace(/^www\./i, "");
            }
        },
        getPelicula: function(){
            axios
                .get(process.env.VUE_APP_URL+`films/${this.$route.params.id}`, {
                    params: {
                        user: this.$store.state.user,
                        directors: 1,
                        actors: 1,
                        producers: 1,
                        categories: 1,
                        families: 1
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.pelicula = rs.data;
                        this.route = 7;
                    }
                })
                .catch(error => this.$response_error(error))
        },
        getFamilias: function(){
            axios
                .get(process.env.VUE_APP_URL+`families`, {
                    params: {
                        user: this.$store.state.user,
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.familias = rs.data;
                        this.route = 5;
                    }
                }).catch(error => this.$response_error(error))
        },
        search: function(search){
            axios
                .get(process.env.VUE_APP_URL+`search`, {
                    params:{
                        search: search,
                        user: this.$store.state.user,
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.result = rs.data;
                    }
                })
                .catch(error => this.$response_error(error))
        },
        goSearch: function(key, value){
            let temp = {};
            temp[key] = [value];
            if(this.$route.name == 'Cartelera')
                this.$refs.cartelera[key] = [value];
            else
                this.$router.push({name: 'Cartelera', params: temp});
            this.buscar = false;
            this.busqueda = null;
            this.result= null;
        },
        cerrarMsg: function(){
            setTimeout();
        }
    },
    watch: {
        $route: function(){
            this.init();
            this.buscar = false;
            this.busqueda = null;
            this.result= null;
        },
        error: function(newval, oldval){
            if(!oldval){
                setTimeout(() => {
                    this.error = null;
                }, 10000);
            }
            else if(!newval && oldval && oldval.type != 0){
                setTimeout(() => {
                    this.error = null;
                }, 1000);
                this.error = oldval;
                this.error.type = 0;
            }
        },
        busqueda: function(newval, oldval){
            if(newval && newval.length >= 3)
                this.search(newval);
            else
                this.result = null;
        },
        modal: function(newval, oldval){
            if(newval)
                document.documentElement.style.overflow = 'hidden';
            else
                document.documentElement.style.overflow = null;
        },
        menu: function(newval, oldval){
            if(newval)
                document.documentElement.style.overflow = 'hidden';
            else
                document.documentElement.style.overflow = null;
        }
    },
    mounted: function(){
        this.init();
    },
}
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to{
        opacity: 0;
    }
</style>